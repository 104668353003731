body {
    font-family: neue-haas-grotesk-display, sans-serif;
}

button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: rgba(127, 72, 150, 1);
    transition: all 300ms ease;
}

    a:hover {
        color: rgba(61, 26, 81, 1);
        text-decoration: none;
    }

.wrapper {
    width: 50%;
    height: 100vh;
    padding: 1.5rem;
    float: left;
}

@media only screen and (max-width: 768px) {

    .wrapper {
        width: 100%;
        padding: 1.5rem 1rem 0;
    }
}

.backgroundImg, .purchaseBackgroundImg, .errorImg, .homeImg {
    position: fixed;
    float: left;
    width: 50%;
    height: 100vh;
    right: 0;
}

.backgroundImg {
    background: top left / cover no-repeat url(https://www.uhm.com/media/3729/mia-sitting-cropped-tall.jpg);
}

.purchaseBackgroundImg {
    background: top left / cover no-repeat url(https://www.uhm.com/media/3729/mia-sitting-cropped-tall.jpg);
}

.errorImg {
    background: center / cover no-repeat url(https://www.uhm.com/media/2647/sophie-elvis-404.jpg);
}

.homeImg {
    background: center / cover no-repeat url(https://www.uhm.com/media/2648/maksym-kaharlytskyi-1kdneez-glu-unsplash.jpg);
}

.logo {
    background: top left / contain no-repeat url(https://www.uhm.com/images/uhm/uhm-logo-color.svg);
    width: auto;
    height: 4rem;
}

@media only screen and (max-width: 800px) {

    .backgroundImg, .purchaseBackgroundImg, .errorImg, .homeImg {
        display: none;
    }

    .logo {
        height: 3rem;
    }

    .footer {
        width: 100%;
    }
}

.content {
    padding-right: 3rem;
}

.loading-message {
    border-radius: .25rem;
    box-shadow: 0 1rem 3.5rem rgba(0, 0, 0, .4);
    padding: 3rem;
    font-size: 1rem;
    position: absolute;
    z-index: 10;
    background-color: #FFFFFF;
    left: 50%;
    top: 50%;
    width: 18.75rem;
    height: 12.5rem;
    margin-left: -9.375rem;
    margin-top: -6.25rem;
    text-align: center;
}

    .loading-message > div {
        margin: 1rem;
    }

.spinner {
    width: 3rem;
    height: 3rem;
    color: #3D1951;
}

h1 {
    margin: 2rem 0 .5rem;
    font-size: clamp(2.5rem, 5vw, 4rem);
    font-weight: 700;
    line-height: clamp(3.5rem, 5vw, 5rem);
    color: #211f22;
}

@media only screen and (max-width: 800px) {

    .content {
        padding: 0;
    }

    h1 {
        margin-top: 1.5rem;
    }
}

h1 span, h2 span {
    color: #2e8337;
}

h1 sup {
    font-size: 0.25em;
    top: -1.5em;
}

h2 {
    font-weight: 400;
    font-size: clamp(1rem, 3vw, 1.5rem);
    line-height: clamp(1.5rem, 4vw, 2rem);
    color: #68646C;
}

input {
    margin: .25rem 0 0 0;
    border-radius: .25rem;
    padding: 1rem;
    width: 100%;
    border: .0625rem solid rgba(0, 0, 0, .4);
}

    input:focus {
        box-shadow: 0 0 0 .25rem rgba(125, 16, 186, 0.4);
    }

.text-danger {
    margin-top: .25rem;
    font-size: .875rem;
}

label {
    font-size: .625rem;
    margin-bottom: .25rem;
    text-transform: uppercase;
    letter-spacing: .03125rem;
}

.fields {
    display: block;
    margin: 1rem 0 0;
    width: 50%;
}

.tcpaCheckbox {
    margin: 1rem 0 0;
    line-height: 1rem;
}

    .tcpaCheckbox input {
        display: inline;
        justify-content: left;
        margin-right: 0.5rem;
        width: auto;
    }

    .tcpaCheckbox label {
        vertical-align: middle;
        word-wrap: break-word;
        display: inline;
        font-size: .75rem;
        letter-spacing: normal;
        text-transform: none;
        margin-bottom: 0.25rem;
    }

.button {
    transition: all 300ms ease;
    margin: 2rem 0;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .03125rem;
    width: 50%;
    height: 3rem;
    border-radius: 3rem;
    background: rgba(61, 25, 81, 1);
    color: rgba(255, 255, 255, 1);
}

.confirmation-message {
    margin: 2rem 0;
}

.button:hover {
    box-shadow: 0 8px 16px rgba(127, 72, 150, .2);
    color: rgba(255, 255, 255, 1);
    background: rgba(127, 72, 150, 1);
    font-weight: 700;
}


@media only screen and (max-width: 800px) {

    .fields {
        float: left;
        margin: 1.5rem 0 0 0;
        width: 100%;
    }

    .button {
        margin: 2rem 0;
        width: 100%;
    }

    .contact {
        top: 3rem;
    }
}

.contact {
    position: relative;
    color: rgba(127, 72, 150, 1);
    font-size: .875rem;
    line-height: 1.25rem;
    padding-left: 2.5rem;
}

    .contact:before {
        position: absolute;
        content: url('https://www.uhm.com/media/2638/phone-call.svg');
        width: 1.5rem;
        height: 1.5rem;
        top: .0625rem;
        left: 0;
    }

.footer {
    margin: 8px 0 0 0;
    font-size: .75rem;
    line-height: 1rem;
}

    .footer img {
        height: 2rem;
        width: auto;
        margin: 1rem 0;
    }
